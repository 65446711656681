// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {ProjectService} from "../app/core/services/project.service";

export const environment = {
  production: true,
  code: 'prod',
  platformAdmin: 'super_admin@realtycms.com.au',
  templates: {
    bulkImport: 'https://realtycms-public-dev.s3.ap-southeast-2.amazonaws.com/assets/templates/RealtyCMS_Bulk_Import_Template.xlsx',
    bulkImportManual: 'https://realtycms-public-dev.s3.ap-southeast-2.amazonaws.com/assets/templates/Bulk_Manual.pdf',
  },
  providers: [],
  sites: {
    frontend: 'https://dev-portal.realtycms.com.au/en/',
    backend: 'https://dev-api.realtycms.com.au/',
    publicMedia: 'https://dev-media.realtycms.com.au/',
    invitation: 'https://dev-portal.realtycms.com.au/en/',
    guestLink: 'https://dev-invitation-link.realtycms.com.au/en/'
  },
  buckets: {
    publicBucket: 'realtycms-public-dev',
    privateBucket: 'realtycms-private-dev'
  },
  domains: {
    s3Public: 'realtycms-public-prod',
    s3Private: 'realtycms-private-prod',
    emailForward: 'dev-forward.realtycms.com.au',
  },
  keys: {
    tinyMCE: 'nbj4ik34na7ek67zkk4ruhrzpevu44b0doxcrsk1sw04m8mh',
  },
  languages: [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'zh-hans',
      name: '简体中文'
    },
    {
      code: 'zh-hant',
      name: '繁體中文'
    },
    {
      code: 'hi',
      name: 'हिंदी'
    },
    {
      code: 'ja',
      name: '日本語'
    },
    {
      code: 'ko',
      name: '한국인'
    }, {
      code: 'fr',
      name: 'Français'
    },
    {
      code: 'vi',
      name: 'Tiếng Việt'
    },
  ],
  urls: {
    auth: {
      apiTokenURL: 'https://dev-api.realtycms.com.au/api/token/',
      apiTokenRefreshURL: 'https://dev-api.realtycms.com.au/api/token/refresh/',
    },
    address: {
      apiAddressURL: 'https://dev-api.realtycms.com.au/api/address/',
      apiSuburbURL: 'https://dev-api.realtycms.com.au/api/suburb/',
    },
    article: {
      apiArticle: 'https://dev-api.realtycms.com.au/api/article_new/',
      apiArticleTag: 'https://dev-api.realtycms.com.au/api/article_tag/',
      apiImageFile: 'https://dev-api.realtycms.com.au/api/article/',
      apiArticleShare: 'https://dev-api.realtycms.com.au/api/article_share/',
      chatgptArticle: 'https://dev-api.realtycms.com.au/api/article_new/chatgpt_article/',
      apiArticleSubscription: 'https://dev-api.realtycms.com.au/api/article_subscription/',
    },
    builder: {
      apiDesignSeries: 'https://dev-api.realtycms.com.au/api/design-series/',
      apiDesignSize: 'https://dev-api.realtycms.com.au/api/design-size/',
      apiFacade: 'https://dev-api.realtycms.com.au/api/facade/',
      apiBuilderFile: 'https://dev-api.realtycms.com.au/api/builder-file/',
      apiBuilderFileInstance: 'https://dev-api.realtycms.com.au/api/files/',
      apiDesignSeriesFile: 'https://dev-api.realtycms.com.au/api/design-series-files/',
      apiDesignSizeFile: 'https://dev-api.realtycms.com.au/api/design-size-files/',
      apiFacadeFile: 'https://dev-api.realtycms.com.au/api/facade-files/',
      apiHLPackage: 'https://dev-api.realtycms.com.au/api/hl-package/',
      apiFeasible: 'https://dev-api.realtycms.com.au/api/feasible/',
    },
    crm: {
      apiDataType: 'https://dev-api.realtycms.com.au/api/data_type/',
      apiFieldType: 'https://dev-api.realtycms.com.au/api/field_type/',
      apiFrontView: 'https://dev-api.realtycms.com.au/api/front_view/',
      apiFrontField: 'https://dev-api.realtycms.com.au/api/front_field/',
      apiContact: 'https://dev-api.realtycms.com.au/api/contact/',
      apiLead: 'https://dev-api.realtycms.com.au/api/lead/',
      apiLeadSource: 'https://dev-api.realtycms.com.au/api/lead_source/',
      apiLeadContact: 'https://dev-api.realtycms.com.au/api/lead_contact/',
      apiContactStream: 'https://dev-api.realtycms.com.au/api/contact_stream/',
      apiLeadStream: 'https://dev-api.realtycms.com.au/api/lead_stream/',
      apiProjectPublish: 'https://dev-api.realtycms.com.au/api/project_publish/',
      apiPlatformOrganization: 'https://dev-api.realtycms.com.au/api/listing_platform/',
      apiProjectAgentRelationSelection: 'https://dev-api.realtycms.com.au/api/project_agent_selection/',
      apiDisplayProject: 'https://dev-api.realtycms.com.au/api/display_project/',
      apiDisplayProjectMediaBase: 'https://dev-api.realtycms.com.au/api/display_file/',
      etagDataType: 'https://dev-api.realtycms.com.au/api/etag/data-type/',
      etagFrontView: 'https://dev-api.realtycms.com.au/api/etag/front-view/',
      apiSendEmail: 'https://dev-api.realtycms.com.au/api/email_sent/',
      apiReceivedEmail: 'https://dev-api.realtycms.com.au/api/email_received/',
      apiEmailImage: 'https://dev-api.realtycms.com.au/api/email_image/'
    },
    guest: {
      apiProjectURL: 'https://dev-api.realtycms.com.au/guest/api/project/',
      apiProjectUnitURL: 'https://dev-api.realtycms.com.au/guest/api/unit/',
      apiProjectImage: 'https://dev-api.realtycms.com.au/guest/api/project_image/',
      apiProjectVideo: 'https://dev-api.realtycms.com.au/guest/api/project_video/',
      apiProjectVR: 'https://dev-api.realtycms.com.au/guest/api/project_vr/',
      apiProjectLevel: 'https://dev-api.realtycms.com.au/guest/api/level/',
      apiProjectLevelPlanImage: 'https://dev-api.realtycms.com.au/guest/api/level_plan/',
      apiProjectFloorPlan: 'https://dev-api.realtycms.com.au/guest/api/floor_plan/',
      apiProjectFloorPlanImage: 'https://dev-api.realtycms.com.au/guest/api/floor_plan_image/',
      apiProjectCourse: 'https://dev-api.realtycms.com.au/guest/api/project_course/',
      apiProjectCourseQA: 'https://dev-api.realtycms.com.au/guest/api/project_course_qa/',
      apiProjectPriceIndex: 'https://dev-api.realtycms.com.au/guest/api/project_price_index/',
      apiProjectResourceFile: 'https://dev-api.realtycms.com.au/guest/api/organization/project/',
      apiProjectDescription: 'https://dev-api.realtycms.com.au/guest/api/project_language/',
      apiInternalProjectURL: 'https://dev-api.realtycms.com.au/guest/api/organization/project/',
      apiProjectSuburbCensus: 'https://dev-api.realtycms.com.au/guest/api/suburb_census/',
      apiProjectSchoolInfo: 'https://dev-api.realtycms.com.au/guest/api/school/',
      apiProjectFileGetPreSignedUrl: 'https://dev-api.realtycms.com.au/guest/api/file_link/',
      apiProjectCourseFileUrl: 'https://dev-api.realtycms.com.au/guest/api/organization/project/',
    },
    language: {
      language: 'https://dev-api.realtycms.com.au/api/language/'
    },
    landing: {
      project: 'https://dev-landing.realtycms.com.au/project/',
      landingToken: 'https://dev-api.realtycms.com.au/api/landing_share_id/',
      form: 'https://dev-landing.realtycms.com.au/form/',
      leadSourceToken: 'https://dev-api.realtycms.com.au/api/landing_form_share/',
    },
    project: {
      apiProjectURL: 'https://dev-api.realtycms.com.au/api/project/',
      apiListProjectURL: 'https://dev-api.realtycms.com.au/api/project_listing/',
      apiProjectConfigURL: 'https://dev-api.realtycms.com.au/api/project_config/',
      apiProjectUnitURL: 'https://dev-api.realtycms.com.au/api/unit/',
      apiProjectSuburbCensus: 'https://dev-api.realtycms.com.au/api/suburb_census/',
      apiProjectSchoolInfo: 'https://dev-api.realtycms.com.au/api/school/',
      apiInternalProjectURL: 'https://dev-api.realtycms.com.au/api/organization/project/',
      apiProjectSpecialURL: 'https://dev-api.realtycms.com.au/api/projects/',
      apiProjectHubURL: 'https://dev-api.realtycms.com.au/api/hub_projects/',
      apiProjectApplicationURL: 'https://dev-api.realtycms.com.au/api/application/',
      apiProjectCollectURL: 'https://dev-api.realtycms.com.au/api/selected_project/',
      apiProjectDescription: 'https://dev-api.realtycms.com.au/api/project_language/',
      apiCache: 'https://dev-api.realtycms.com.au/api/cache/',
      apiProjectUnit: 'https://dev-api.realtycms.com.au/api/unit/',
      apiUnitTag: 'https://dev-api.realtycms.com.au/api/unit_tag/',
      apiUnitTagRelation: 'https://dev-api.realtycms.com.au/api/unit_tag_relation/',
      apiUnitCache: 'https://dev-api.realtycms.com.au/api/unit_cache/',
      apiProjectMedia: 'https://dev-api.realtycms.com.au/api/organization/projects/',
      apiProjectLevel: 'https://dev-api.realtycms.com.au/api/level/',
      apiProjectLevelPlanImage: 'https://dev-api.realtycms.com.au/api/level_plan/',
      apiProjectFloorPlan: 'https://dev-api.realtycms.com.au/api/floor_plan/',
      apiProjectFloorPlanImage: 'https://dev-api.realtycms.com.au/api/floor_plan_image/',
      apiProjectImage: 'https://dev-api.realtycms.com.au/api/project_image/',
      apiProjectVideo: 'https://dev-api.realtycms.com.au/api/project_video/',
      apiProjectVR: 'https://dev-api.realtycms.com.au/api/project_vr/',
      apiProjectCourse: 'https://dev-api.realtycms.com.au/api/project_course/',
      apiProjectCourseQA: 'https://dev-api.realtycms.com.au/api/project_course_qa/',
      apiProjectNews: 'https://dev-api.realtycms.com.au/api/news/',
      apiProjectPromotion: 'https://dev-api.realtycms.com.au/api/promotion/',
      apiProjectPromotionUnitRelation: 'https://dev-api.realtycms.com.au/api/promotion_unit/',
      apiProjectBuilding: 'https://dev-api.realtycms.com.au/api/building/',
      apiProjectPriceIndex: 'https://dev-api.realtycms.com.au/api/project_price_index/',
      apiProjectInvitation: 'https://dev-api.realtycms.com.au/api/invitation/',
      apiProjectBatchInvitation: 'https://dev-api.realtycms.com.au/api/batch_invitation/',
      apiProjectAgentRelation: 'https://dev-api.realtycms.com.au/api/project_agent_relation/',
      apiProjectAgentRelationExtra: 'https://dev-api.realtycms.com.au/api/project_agent_relation_extra/',
      apiUnitAgentRelation: 'https://dev-api.realtycms.com.au/api/unit_agent_relation/',
      apiProjectFileUploadPreSignedUrl: 'https://dev-api.realtycms.com.au/api/file_upload_link/',
      apiProjectFileGetPreSignedUrl: 'https://dev-api.realtycms.com.au/api/file_link/',
      apiProjectListExport: 'https://dev-api.realtycms.com.au/api/project/export_project_list/',
      apiProjectProposal: 'https://dev-api.realtycms.com.au/api/proposal/',
      apiInvitationLink: 'https://dev-api.realtycms.com.au/api/invitation_link/',
      apiInvitationLinkEmailConfirm: 'https://dev-api.realtycms.com.au/api/invitation_link_email/',
      apiProjectChannel: 'https://dev-api.realtycms.com.au/api/project_channel/',
      apiProjectChannelManager: 'https://dev-api.realtycms.com.au/api/project_channel_manager/',
      apiReservation: 'https://dev-api.realtycms.com.au/api/reservation/',
      apiReservationAttachment: 'https://dev-api.realtycms.com.au/api/reservation_attach/',
      apiReservationDeposit: 'https://dev-api.realtycms.com.au/api/reservation_deposit/',
      apiProjectSelection: 'https://dev-api.realtycms.com.au/api/project_selection/',
      apiLandMasterPlan: 'https://dev-api.realtycms.com.au/api/land-master-plans/',
      apiLandMasterPlanFile: 'https://dev-api.realtycms.com.au/api/land-master-plans/',
      apiDesignFile: 'https://d1d546whmtega2.cloudfront.net/',
      apiProjectMasterPlanPoint: 'https://dev-api.realtycms.com.au/api/land-master-plan-points/',
      apiBuilderUnit: 'https://dev-api.realtycms.com.au/api/builder_unit/',
    },
    notification: {
      apiNotification: 'https://dev-api.realtycms.com.au/api/notification/',
      organizationMessage: 'https://dev-api.realtycms.com.au/api/org_message/',
      organizationMessageFile: 'https://dev-api.realtycms.com.au/api/org_message_file/',
      promotion: 'https://dev-api.realtycms.com.au/api/promotion/',
      registerExpoToken: 'https://dev-api.realtycms.com.au/api/exp_push_token/',
      deregisterExpoToken: 'https://dev-api.realtycms.com.au/api/exp_push_token/deregister/',
    },
    staff: {
      staff: 'https://dev-api.realtycms.com.au/api/organisation_staff/',
      staffInfo: 'https://dev-api.realtycms.com.au/api/organisation_staff/user_info/',
      staffFile: 'https://dev-api.realtycms.com.au/api/organization/staffs/',
      passwordReset: 'https://dev-api.realtycms.com.au/api/password_reset/',
      passwordResetConfirm: 'https://dev-api.realtycms.com.au/api/password_reset/confirm/',
      passwordTokenCheck: 'https://dev-api.realtycms.com.au/api/password_reset/validate_token/ '
    },
    organization: {
      organization: 'https://dev-api.realtycms.com.au/api/organisation/',
      organizationRelation: 'https://dev-api.realtycms.com.au/api/organisation_rel/',
      organizationRelationInvitation: 'https://dev-api.realtycms.com.au/api/organisation_rel_invitation/',
      organizationInfo: 'https://dev-api.realtycms.com.au/api/organisation/organization_info/',
      organizationSubscriptionInfo: 'https://dev-api.realtycms.com.au/api/organisation/subscription_info/',
      organizationFile: 'https://dev-api.realtycms.com.au/api/organization/files/',
      organizationRoles: 'https://dev-api.realtycms.com.au/api/organisation_role/',
      organizationRolePermission: 'https://dev-api.realtycms.com.au/api/organisation_role_permission/',
      organizationInvitations: 'https://dev-api.realtycms.com.au/api/staff_invitation/',
      permissionListUser: 'https://dev-api.realtycms.com.au/api/permission/list_my_permission/',
      userRoleVersion: 'https://dev-api.realtycms.com.au/api/permission/my_role_version/',
      membership: 'https://dev-api.realtycms.com.au/api/membership/',
      proAccount: 'https://dev-api.realtycms.com.au/api/pro_account/',
      membershipEnquiry: 'https://dev-api.realtycms.com.au/api/membership_enquiry/',
      paymentChangeEnquiry: 'https://dev-api.realtycms.com.au/api/payment_enquiry/',
      syncLink: 'https://dev-api.realtycms.com.au/api/sync_link/',
      syncLinkEmailConfirm: 'https://dev-api.realtycms.com.au/api/sync_link_email/',
      channelTag: 'https://dev-api.realtycms.com.au/api/channel_tag/',
      organizationSignUp: 'https://dev-api.realtycms.com.au/api/organization_sign_up/',
    },
    quickSearch: {
      search: 'https://dev-api.realtycms.com.au/api/search/',
    },
    service: {
      thumbnail: "https://service.realtycms.com.au/image/",
      getShare: "https://service.realtycms.com.au/get-share/",
      share: "https://service.realtycms.com.au/share/",
      pdfImages: "https://ayjh9ieoel.execute-api.ap-southeast-2.amazonaws.com/images",
      article: "https://ayjh9ieoel.execute-api.ap-southeast-2.amazonaws.com/article/"
    },
    system: {
      apiFrontendInfoURL: 'https://dev-api.realtycms.com.au/api/current_frontend_info/',
    },
    // TODO: change urls after final confirm about product and payment links
    payment: {
      premium: {
        monthly: {
          packageOnly: 'https://buy.stripe.com/test_7sI7tc3N9aFB62I8wx',
          packageWithLicense: 'https://buy.stripe.com/test_dR6cNwcjF2952Qw7sx',
          packageWithExtra: 'https://buy.stripe.com/test_cN28xg2J59Bx1Ms147',
          packageWithAll: 'https://buy.stripe.com/test_28oeVEfvR5lh4YE5kr'
        },
        annually: {
          packageOnly: 'https://buy.stripe.com/test_14keVE0AXaFB76McMO',
          packageWithLicense: 'https://buy.stripe.com/test_6oE6p8bfB2953UA14a',
          packageWithExtra: 'https://buy.stripe.com/test_00g5l46Zl5lh3UA7sw',
          packageWithAll: 'https://buy.stripe.com/test_28o28S5VhaFB62I14c'
        }
      },
    },
    // TODO: add url when ready
    australiaAgency: {
      checkOrganization: 'https://dev-api.realtycms.com.au/api/aa_check/',
      getAgencies: 'https://dev-api.realtycms.com.au/api/cms_agency/',
      followUp: 'https://dev-api.realtycms.com.au/api/follow_up/'
    }

  },
  posthog: {
    enabled: false,
  },
  whiteLabel: {
    mapping: {
      'dev-projectsc21.realtycms.com.au': {
        companyInfo: {
          companyName: '21 Century',
          companyLogo: 'https://dev-media.realtycms.com.au/assets/images/c21.png',
          companyHeadingLogo: 'https://dev-media.realtycms.com.au/assets/images/c21-logo.png',
        },
        syncInfo: {
          syncLink: 'https://dev-portal.realtycms.com.au/en/auth/sync-link/32db3706-c861-40e5-9bb9-e064582fc3c8'
        },
        loginInfo: {
          email: 't8@meetkol.com'
        },
        projectSelection: '9ab0c933-5a4f-4dbb-8353-dab082f2bd73',
        themeClass: 'century-21-theme',
        mainStyleColor: {
          'primary-color': '#b2a071',
          'bg-color': '#dad4bf'
        },
        loginPageDisplay: {
          'background-container': {
            'background-image': 'url(https://dev-media.realtycms.com.au/assets/images/408.jpeg)',
            'width': '100vw',
            'height': '100vh',
          },
          'login-form': {},
          'film': {
            'width': '100%',
            'height': '100%',
            'background-color': 'rgba(0,0,0,.03)',
            'position': 'absolute',
            'left': 0,
            'top': 0,
            'backdrop-filter': 'blur(10px)',
            'box-shadow': 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            '-webkit-backdrop-filter': 'blur(10px)',
            'background': '#252526',
            'opacity': 0.9
          },
          'apply-button': {
            'color': '#fff'
          },
          'footer': {
            'color': '#fff'
          }
        },
        mainLayoutDisplay: {
          'header-wrap': {
            'background-color': '#505050',
          },
          'header-icon-img': {
            'width': '60px',
            'height': '60px'
          },
          'header-icon-mobile-img': {
            'width': '30px',
            'height': '30px'
          },
          'nav-text': {
            color: '#fff'
          },
          'sync-button': {
            color: '#fff'
          }
        },
        filterDisplay: {
          'filter-container': {
            'background-color': '#dad4bf',
            'border-color': '#b2a071'
          }
        },
        projectFilterDialogDisplay: {
          'button-bg-color': {
            'background-color': '#b2a071'
          },
          'button-text-color': {
            'color': '#b2a071'
          }
        },
        quickSearchFilterDialogDisplay: {
          'button-bg-color': {
            'background-color': '#b2a071'
          },
          'button-text-color': {
            'color': '#b2a071'
          }
        },
        projectUnitDialogDisplay: {
          'bg-color': {
            'background-color': '#b2a071',
          },
          'text-color': {
            'color': '#b2a071',
          }
        }
      },
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
