import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AppService} from "./core/services/app.service";
import {ThemeServiceService} from "./core/services/theme-service.service";
import {Observable, Subscription} from "rxjs";
import {WhiteLabelService} from "./core/services/white-label.service";
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'project-partner-front-end';
  themeSubscription: Subscription;
  currentTheme: string = 'default';

  constructor(private appService: AppService,
              private router: Router,
              private themeService: ThemeServiceService,
              private whiteLabelService: WhiteLabelService) {
    this.themeSubscription = this.themeService.currentTheme.subscribe((theme: any) => {
      this.currentTheme = theme;
    })
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/crm')) {
          this.themeService.setTheme('crm-theme');
        } else {
          this.themeService.setTheme('default-theme');
        }
      }
    });
    if (this.whiteLabelService.whiteLabelEnabled) {
      this.themeService.setDarkTheme(!!this.whiteLabelService.currentLabelSetting['themeClass']
        ? this.whiteLabelService.currentLabelSetting['themeClass'] : 'default');

    } else {
      this.themeService.setDarkTheme('default');
    }
    window.addEventListener("message",
      message => {
        if (message.type == "message") {
          this.appService.appAdapter(message.data);
        }
      });
    document.addEventListener("message", message => {
      // This is for android
      if (message.type == "message") {
        // @ts-ignore
        this.appService.appAdapter(message.data);
      }
    });
    document.addEventListener('DOMNodeInserted', function () {
      const elements = document.querySelectorAll('[aria-owns]');

      elements.forEach(element => {
        element.removeAttribute('aria-owns');
      });
    });
  }

  ngOnDestroy() {
    this.themeSubscription.unsubscribe();
  }


}
