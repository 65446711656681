import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {AuthenticationLayoutComponent} from './layouts/authentication-layout/authentication-layout.component';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component';
import {EmailMaskPipe} from './pipes/email-mask.pipe';
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {VideoCardComponent} from './components/video-card/video-card.component';
import {DragUploadDirective} from './directives/drag-upload.directive';
import {AuthImagePipe} from './pipes/auth-image.pipe';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {FileSizePipe} from './pipes/file-size.pipe';
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {ShortNumberPipe} from './pipes/short-number.pipe';
import {HelpCenterDialogComponent} from './components/help-center-dialog/help-center-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {MembershipLayerComponent} from './components/membership-layer/membership-layer.component';
import {
  FunctionRestrictedDialogComponent
} from './components/function-restricted-dialog/function-restricted-dialog.component';
import {
  MembershipEnquiryDialogComponent
} from './components/membership-enquiry-dialog/membership-enquiry-dialog.component';
import {ProjectListingTableComponent} from './components/project-listing-table/project-listing-table.component';
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyPaginatorModule as MatPaginatorModule} from "@angular/material/legacy-paginator";
import {
  ProjectSourceLinkDialogComponent
} from './components/project-source-link-dialog/project-source-link-dialog.component';
import {ProjectNoteDialogComponent} from './components/project-note-dialog/project-note-dialog.component';
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import {
  ProjectSelectionDialogComponent
} from './components/project-selection-dialog/project-selection-dialog.component';
import {ProjectFilterComponent} from './components/project-filter/project-filter.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from "@angular/material/legacy-autocomplete";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {
  ProjectSelectionConfirmDialogComponent
} from './components/project-selection-confirm-dialog/project-selection-confirm-dialog.component';
import {
  ProjectBatchInvitationListingDialogComponent
} from './components/project-batch-invitation-listing-dialog/project-batch-invitation-listing-dialog.component';
import {PromotionInfoDialogComponent} from './components/promotion-info-dialog/promotion-info-dialog.component';
import {IframeViewerDialogComponent} from './components/iframe-viewer-dialog/iframe-viewer-dialog.component';
import {AiDialogComponent} from './components/ai-dialog/ai-dialog.component';
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {AgencySelectDialogComponent} from './components/agency-select-dialog/agency-select-dialog.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {StaffSelectDialogComponent} from './components/staff-select-dialog/staff-select-dialog.component';
import {
  ProjectChannelManagementDialogComponent
} from './components/project-channel-management-dialog/project-channel-management-dialog.component';
import {
  ProjectChannelManagerSelectDialogComponent
} from './components/project-channel-manager-select-dialog/project-channel-manager-select-dialog.component';
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import { GuestLoginLayoutComponent } from './layouts/guest-login-layout/guest-login-layout.component';
import { GuestLinkLayoutComponent } from './layouts/guest-link-layout/guest-link-layout.component';
import { ProjectVerticalCardComponent } from './components/project-vertical-card/project-vertical-card.component';
import { ProjectHorizontalCardComponent } from './components/project-horizontal-card/project-horizontal-card.component';
import {MatRippleModule} from "@angular/material/core";
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { ProjectHorizontalCardSmallComponent } from './components/project-horizontal-card-small/project-horizontal-card-small.component';
import { ProjectLocalSelectionDialogComponent } from './components/project-local-selection-dialog/project-local-selection-dialog.component';
import { CrmLayoutComponent } from './layouts/crm-layout/crm-layout.component';
import { ProjectFilterDialogComponent } from './components/project-filter-dialog/project-filter-dialog.component';
import {MatLegacyRadioModule} from "@angular/material/legacy-radio";
import { DownloadingFileDialogComponent } from './components/downloading-file-dialog/downloading-file-dialog.component';
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";
import { InteractiveImgComponent } from './components/builder/interactive-img/interactive-img.component';
import { BuilderDesignCardComponent } from './components/builder/builder-design-card/builder-design-card.component';
import { BuilderFacadeCardComponent } from './components/builder/builder-facade-card/builder-facade-card.component';
import { InteractiveMarkerEditDialogComponent } from './components/builder/interactive-marker-edit-dialog/interactive-marker-edit-dialog.component';
import { PackageDetailDialogComponent } from './components/package-detail-dialog/package-detail-dialog.component';
import {MatExpansionModule} from "@angular/material/expansion";
import { PackageCardComponent } from './components/builder/package-card/package-card.component';
import { ProjectInvitationSendingDialogComponent } from './components/project-invitation-sending-dialog/project-invitation-sending-dialog.component';
import { ProjectInvitationDetailDialogComponent } from './components/project-invitation-detail-dialog/project-invitation-detail-dialog.component';
import { BuilderSeriesCardComponent } from './components/builder/builder-series-card/builder-series-card.component';


@NgModule({
  declarations: [
    AuthenticationLayoutComponent,
    MainLayoutComponent,
    EmailMaskPipe,
    BreadcrumbComponent,
    VideoCardComponent,
    DragUploadDirective,
    AuthImagePipe,
    FileSizePipe,
    ShortNumberPipe,
    MembershipLayerComponent,
    HelpCenterDialogComponent,
    FunctionRestrictedDialogComponent,
    MembershipEnquiryDialogComponent,
    ProjectListingTableComponent,
    ProjectSourceLinkDialogComponent,
    ProjectNoteDialogComponent,
    ProjectSelectionDialogComponent,
    ProjectFilterComponent,
    ProjectSelectionConfirmDialogComponent,
    ProjectBatchInvitationListingDialogComponent,
    PromotionInfoDialogComponent,
    IframeViewerDialogComponent,
    AiDialogComponent,
    AgencySelectDialogComponent,
    StaffSelectDialogComponent,
    ProjectChannelManagementDialogComponent,
    ProjectChannelManagerSelectDialogComponent,
    GuestLoginLayoutComponent,
    GuestLinkLayoutComponent,
    ProjectVerticalCardComponent,
    ProjectHorizontalCardComponent,
    RelativeTimePipe,
    ProjectHorizontalCardSmallComponent,
    ProjectLocalSelectionDialogComponent,
    CrmLayoutComponent,
    ProjectFilterDialogComponent,
    DownloadingFileDialogComponent,
    InteractiveImgComponent,
    BuilderDesignCardComponent,
    BuilderFacadeCardComponent,
    InteractiveMarkerEditDialogComponent,
    PackageDetailDialogComponent,
    PackageCardComponent,
    ProjectInvitationSendingDialogComponent,
    ProjectInvitationDetailDialogComponent,
    BuilderSeriesCardComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatCheckboxModule,
    MatChipsModule,
    MatRippleModule,
    MatLegacyRadioModule,
    CdkDrag,
    CdkDragHandle,
    MatExpansionModule,
    NgOptimizedImage,
  ],
  exports: [
    AuthenticationLayoutComponent,
    MainLayoutComponent,
    EmailMaskPipe,
    AuthImagePipe,
    FileSizePipe,
    ShortNumberPipe,
    FlexLayoutModule,
    VideoCardComponent,
    DragUploadDirective,
    MembershipLayerComponent,
    ProjectListingTableComponent,
    ProjectVerticalCardComponent,
    ProjectHorizontalCardComponent,
    RelativeTimePipe,
    ProjectHorizontalCardSmallComponent,
    ProjectFilterComponent,
    InteractiveImgComponent,
    BuilderDesignCardComponent,
    BuilderFacadeCardComponent,
    PackageDetailDialogComponent,
    PackageCardComponent,
    BuilderSeriesCardComponent
  ]
})
export class SharedModule {
}
